<template>
  <a-modal
    :visible="visible"
    cancelText="取消"
    centered
    dialogClass="payment-method-modal"
    okText="确定"
    title="选择支付方式"
    width="40%"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <a-row :gutter="[24, 0]">
      <a-col :md="8" :span="24">
        <a-card
          :headStyle="payType === 'ali' ? { color: '#1890FF', fontWeight: '600' } : {}"
          :hoverable="true"
          :style="{ borderColor: payType === 'ali' ? '#801602' : '#e8e8e8' }"
          class="payment-method-card"
          title="支付宝"
          @click="payType = 'ali'"
        >
          <img :src="require('@/assets/logos/alipay.png')" alt="">
        </a-card>
      </a-col>
      <a-col :md="8" :span="24">
        <a-card
          :headStyle="payType === 'wx' ? { color: '#278320', fontWeight: '600' } : {}"
          :hoverable="true"
          :style="{ borderColor: payType === 'wx' ? '#801602' : '#e8e8e8' }"
          class="payment-method-card"
          title="微信支付"
          @click="payType = 'wx'"
        >
          <img :src="require('@/assets/logos/wechat.png')" alt="">
        </a-card>
      </a-col>
      <a-col :md="8" :span="24">
        <a-card
          :headStyle="payType === 'yue' ? { color: '#fc591b', fontWeight: '600' } : {}"
          :hoverable="true"
          :style="{ borderColor: payType === 'yue' ? '#801602' : '#e8e8e8' }"
          class="payment-method-card"
          title="余额支付"
          @click="payType = 'yue'"
        >
          <img alt="" src="https://file.jingpingo.com/supply/material/f4a377b6-7538-4bc0-a316-117fba88eb5d.png">
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'

/**
 * @author  XuHongli
 * @date  2022/9/14 17:13
 * @version 1.0
 * @description
 */
export default {
  name: 'PaymentMethodModal',
  mixins: [ ShowModalMixin ],
  data() {
    return {
      payType: 'wx',
    }
  },
  methods: {
    handleOk() {
      this.$emit('ok', this.payType)
      this.closeModal()
    }
  },
}
</script>

<style scoped>

</style>
