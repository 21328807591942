<template>
  <a-modal
    :visible="visible"
    cancelText="取消"
    centered
    dialogClass="payment-method-modal"
    okText="确定"
    title="微信支付"
    @cancel="closeModal"
  >
    <div v-if="!payOk">
      <p class="desc m-0">
        请使用微信扫码支付
      </p>
      <div class="codeBox">
        <a-spin :spinning="loading" tip="Loading...">
          <vueQr
            v-if="qrcode.length > 0"
            :logo-src="require('@/assets/logos/wechat.png')"
            :margin="30"
            :size="300"
            :text="qrcode"
            :white-margin="true" />
        </a-spin>

      </div>
      <div style="text-align: center;margin-top: 20px;">
        <h5 v-if="wxpay" style="color:red;">总金额：{{ wxpay.toFixed(2) }}</h5>
      </div>
    </div>
    <a-result
      v-else
      status="success"
      sub-title="请点击按钮，关闭弹窗"
      title="您已成功支付"
    >
      <template #extra>
        <a-button key="ok" type="primary" @click="handleOk()">
          完成
        </a-button>
      </template>
    </a-result>
    <template slot="footer">
    </template>

  </a-modal>
</template>

<script>
import vueQr from 'vue-qr'
import ShowModalMixin from '@/mixin/ShowModalMixin'
import { getOrderBooleanStatus, getOrdersStatus, unifiedOrder } from '@/api/Order'

/**
 * @author  XuHongli
 * @date  2022/9/14 17:38
 * @version 1.0
 * @description 微信支付二维码弹窗
 */
export default {
  name: 'WechatPayModal',
  components: { vueQr },
  props: {
    identifying: {
      type: Number,
    },
    orderId: { // 必填
      type: String,
      required: true
    },
    orderSn: { // 批量下单的时候 OrderSn可以为传空串
      type: String,
      required: true
    },
    codeUrl: {
      type: String,
      default: 'nullCodeUrl'
    },
    wxpay:{
      type:[String,Number],
      default:''
    }
  },
  mixins: [ ShowModalMixin ],
  data() {
    return {
      setIVal: null, // 定时器轮询订单状态
      qrcode: '',
      payOk: false,
      loading: false,
    }
  },
  created() {
    console.log(this.identifying, '标识')
    const postData = {
      orderId: this.orderId,
      orderSn: this.orderSn,
      payType: 'wx'
    }

    this.loading = true

    console.log(this.codeUrl === 'nullCodeUrl')

    // 当没有传入codeUrl时候自动调取微信下单
    this.loading = false
    this.qrcode = this.codeUrl
    if (this.codeUrl === 'nullCodeUrl' || this.identifying === 1) {
      unifiedOrder(postData).then((res) => {
        this.qrcode = res.data.codeUrl
        this.loading = false
        this.setIVal = setInterval(() => {
          getOrderBooleanStatus(this.orderId).then(res => {
            this.payOk = res.data
            if (this.payOk === true) {
              clearInterval(this.setIVal)
            }
          })
        }, 3000)
      })
    } else {
      this.loading = false
      this.qrcode = this.codeUrl
    }
  },
  watch: {
    codeUrl(newValue, oldValue) {
      console.log('监听', newValue, oldValue)
      if (newValue !== 'nullCodeUrl' && newValue !== oldValue && this.identifying === 2) {
        this.qrcode = newValue
        this.setIVal = setInterval(() => {
          getOrdersStatus(this.orderId).then((res) => {
            this.payOk = res.data
            if (this.payOk === true) {
              clearInterval(this.setIVal)
              this.$router.go(0)
            }
          })
        }, 3000)
      }
    }
  },
  methods: {
    handleOk() {
      this.$router.replace({path: '/user/order'})
      this.closeModal()
    }
  },
  beforeDestroy() {
    clearInterval(this.setIVal)
  }
}
</script>

<style lang="scss" scoped>
div.codeBox{
  margin: 12px 0 0 0;
  padding: 20px 0;
  min-height: 300px;
  text-align: center;
  background: #F5F8FC;
}
p.desc{
  margin-top: 8px;
  color: #7A8499;
  font-size: 16px;
  line-height: 22px;
}
</style>
