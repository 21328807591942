/**
 * @author  XuHongli
 * @date  2022/8/30 16:56
 * @version 1.0
 * @description
 */


/** 商城用户代发订单 */
export const const2 = [
  {
    title: '商品信息',
    dataIndex: 'supplyProduct',
    scopedSlots: { customRender: 'description' },
    width: '500px',
  },
  {
    title: '商城用户信息',
    dataIndex: 'userShopInfo',
    scopedSlots: { customRender: 'userShopInfo' },
    width: '300px',
  },
  {
    title: '代发状态',
    dataIndex: 'applyState',
    scopedSlots: { customRender: 'applyState' },
    align: 'center',
    width: '105px',
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '150px',
    scopedSlots: { customRender: 'action' },
    align: 'center'
  },
]

// TODO 结算金额统计未完成
export const columns = [
  {
    title: '商品信息',
    dataIndex: 'supplyProduct',
    scopedSlots: { customRender: 'description' },
    width: '550px',
  },
  {
    title: '结算金额',
    dataIndex: 'salesPrice',
    scopedSlots: { customRender: 'salesPrice' },
    align: 'center',
    needTotalList: true,
    width: '150px',
  },
  {
    title: '代发状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
    width: '105px',
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '100px',
    scopedSlots: { customRender: 'action' },
    align: 'center'
  },
  {
    title: '商城用户状态',
    dataIndex: 'userStatus',
    scopedSlots: { customRender: 'userStatus' },
    align: 'center',
    width: '105px',
  },
  // {
  //   title: '备注',
  //   dataIndex: 'remark',
  //   scopedSlots: { customRender: 'remark' },
  //   align: 'center'
  // },


]
