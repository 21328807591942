<template>
  <a-modal
    :centered="true"
    :visible="visible"
    title="申请售后"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <a-form ref="form" :form="form" layout="vertical">
      <a-form-item label="退款类型">
        <a-radio-group
          v-decorator="[
            'refundStatus',
            {
              rules: [{ required: true, message: '请选择退款类型!' }],
              initialValue: '1',
            }
          ]"
        >
          <a-radio value="21">退款</a-radio>
          <a-radio v-if="['2', '3'].includes(selectOrderObj.status)" value="22">退货退款</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item class="tuikuan" label="退款金额：">
        <div style="color: red">{{ selectOrderObj.salesPrice }}</div>
      </a-form-item>
      <a-form-item class="tuikuan" label="退款数量：">
        <div v-for="(supplyOrderItem, index) in selectOrderObj.supplyOrderItem" :key="supplyOrderItem.id + '-' + index">X{{ supplyOrderItem.quantity }}</div>
      </a-form-item>
      <a-form-item label="退款原因">
        <a-input
          v-decorator="['remark',
                        { rules: [{ required: true, message: '请输入退货原因!' }] }]"
          placeholder="请输入退货原因"
          style="min-height: 130px"
          type="textarea"
        />
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'
import { refundOrder } from '@/api/Order'

/**
 * @author  XuHongli
 * @date  2022/9/15 16:10
 * @version 1.0
 * @description
 */
export default {
  name: 'RefundModal',
  mixins: [ ShowModalMixin ],
  props: {
    selectOrderObj: {
      type: Object,
      default: () => {}
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'RefundForm' }),
    }
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          refundOrder(Object.assign({ orderId: this.id }, values)).then((res) => {
            this.$emit('ok', res.data)
            this.$nextTick(() => {
              this.form.resetFields()
            })
            this.closeModal()
          })
        }
      })
    }
  },
}
</script>

<style scoped>
.tuikuan >>> .ant-col {
  display: inline-block;
}
</style>
