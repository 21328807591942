/**
 * @author  XuHongli
 * @date  2022/9/9 17:05
 * @version 1.0
 * @description
 */

export const OrderCancelRefunds = [
  '我不想买了',
  '信息填写错误, 重新拍',
  '卖家缺货',
  '同城见面交易',
  '付款遇到问题(余额不足, 不知道怎么付款)',
  '拍错了',
  '其他原因'
]
