/**
 * @author  XuHongli
 * @date  2022/9/5 17:44
 * @version 1.0
 * @description 订单状态值Map
 */

// 商城用户代发状态
export const DropStatusMap = {
  0: {
    status: 'default',
    text: '未代发',
    color: 'rgb(43,43,44)'
  },
  1: {
    status: 'success',
    text: '订单创建成功',
    color: 'rgb(15,255,0)'
  },
  2: {
    status: 'error',
    text: '代发失败',
    color: '#f5222d'
  },
}

export const OrderStatusMap = {
  0: {
    status: 'error',
    text: '待付款',
    color: '#f5222d'
  },
  1: {
    status: 'warning',
    text: '待发货',
    color: '#fadb14'
  },
  2: {
    status: 'processing',
    text: '待收货',
    color: 'rgba(39,222,15,0.32)'
  },
  3: {
    status: 'success',
    text: '确认收货/已完成',
    color: 'rgb(15,255,0)'
  },
  4: {
    status: 'success',
    text: 'Null'
  },
  5: {
    status: 'error',
    text: '已关闭'
  },
  6: {
    status: 'warning',
    text: '退货/退款中',
    color: '#801602'
  },
  7: {
    status: 'success',
    text: '完成退货/退款中',
    color: '#801602'
  },
  8: {
    status: 'error',
    text: '退货/退款失败',
    color: '#801602'
  },
  9: {
    status: 'default',
    text: '已取消',
    color: 'rgb(43,43,44)'
  },
}

//  退货退款的状态
export const OrderRefundStatusMap = {
  20: {
    refundStatus: 'default',
    text: '撤销退换申请'
  },
  21: {
    refundStatus: 'warning',
    text: '退款申请中'
  },
  211: {
    refundStatus: 'success',
    text: '同意退款'
  },
  212: {
    refundStatus: 'error',
    text: '拒绝退款'
  },
  22: {
    refundStatus: 'warning',
    text: '退货退款申请中'
  },
  221: {
    refundStatus: 'success',
    text: '同意退货退款'
  },
  222: {
    refundStatus: 'error',
    text: '拒绝退货退款'
  },
  2211: {
    refundStatus: 'success',
    text: '收到退货同意退款'
  },
  2212: {
    refundStatus: 'error',
    text: '收到退货拒绝退款'
  },
  230: {
    refundStatus: 'warning',
    text: '待验收'
  },
  231: {
    refundStatus: 'success',
    text: '已完成'
  },
  232: {
    refundStatus: 'error',
    text: '拒绝验收'
  }
}
