<template>
  <a-modal
    title="选择其他地址"
    :centered="true"
    :width="800"
    :visible="visible"
    @ok="handleOk"
    @cancel="closeModal"
  >
    <AddressTable :select-mode="true" @select="selectAddress" @del="onDel"></AddressTable>
  </a-modal>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'
import AddressTable from '@/views/User/Address/AddressPop'

/**
 * @author  XuHongli
 * @date  2022/9/8 15:19
 * @version 1.0
 * @description
 */
export default {
  name: 'AddressSelectModal',
  components: { AddressTable },
  props:{
    closeMo:{
      type:[Number,String],
      default:''
    }
  },
  mixins: [ ShowModalMixin ],
  data() {
    return {
      val: '',
      values:''
    }
  },
  watch:{
    closeMo(n,o){
      console.log('nnnn',n)
      console.log('o000',o)
      if(o){
        this.closeModal()
      }
    }
  },
  methods: {
    handleOk() {
      // if (this.val === '' ) return this.$message.error('还未选择地址')
      if(this.values === '') return  this.$message.error('还未选择地址')
      console.log('values////',this.values)
      this.closeModal()
      this.$emit('select', this.val,this.closeModal())
    },
    selectAddress(val,values) {
      this.val = val
      console.log('values',values)
      this.values = values
    },
    onDel() {
      this.$emit('del')
    }
  },
}
</script>

<style scoped>

</style>
