<template>
  <div class="AddressTable">

    <div class="address_box">
      <div class="left">收货地址</div>
      <!-- <div class="right">
        <a-icon type="info-circle" theme="twoTone" />
        &nbsp;&nbsp;
        双击地址块进行修改
      </div> -->
      <div class="add" @click="handleAdd">添加地址</div>
    </div>
    <div class="address_list">
      <div v-for="(item, index) in addressData" :key="index" class="address_more" @dblclick="update(item)">
        <div>
          <el-radio v-model="values" :label="index" @change="icout(item)">{{ item.userName + ' ' + item.telNum
          }}</el-radio>
          <div>
            <div style="margin-top:5px">{{ item.provinceName + item.countyName + item.cityName }}</div>
          </div>
        </div>

        <div class="btn">
          <div @click="showDeleteConfirm(item)">删除</div>&nbsp;&nbsp;
          <div style="color:#409EFF" @click="update(item)">编辑</div>&nbsp;&nbsp;
          <a-switch :checked="Boolean(item.isDefault)" :class="[isActive && item.isDefault == 1 ? 'active' : 'noActive']"
            checked-children="默认" un-checked-children="关" @change="changeIsDefault(item)"> </a-switch>
        </div>
      </div>
    </div>

    <AddressModal v-model="AddressModal" :model="mdl" @ok="() => this.getAddress()"></AddressModal>
  </div>
</template>

<script>
import { columns } from '../AddressTable/AddressTableColumns'
import TabButtonGroup from '@/components/tabButtonGroup'
import { deleteUserAddress, getUserAddress, updateUserAddress } from '@/api/Address'
import AddressModal from '@/views/User/Address/AddressModal'
import STable from '@/components/Table'
/**
 * @author  XuHongli
 * @date  2022/8/31 22:21
 * @version 1.0
 * @description
 */
export default {
  name: 'AddressTable',
  props: {
    selectMode: { // 选择器模式
      type: Boolean,
      default: false
    },
  },
  components: { AddressModal, TabButtonGroup, STable },
  data() {
    return {
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return getUserAddress(requestParameters).then((res) => {
          return res.data
        })
      },
      queryParam: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 暂时无实际意义 Tabs栏值
      TabsValue: '0',
      // 添加/修改 地址弹窗
      AddressModal: false,
      // 传入Modal的数据
      mdl: null,
      // 传入城市接口地址数据
      citys: null,
      isActive: true,
      addressData: [],
      values: ''
    }
  },
  watch: {
    values(newValue, oldValue) {
      console.log('新值', newValue, oldValue)
      if (newValue !== '') {
        this.$emit('select', this.addressData[this.values], this.values)
      }
    }
  },
  created() {
    this.getAddress()
  },
  methods: {
    getAddress() {
      getUserAddress().then((res) => {
        this.addressData = res.data.records
        this.$emit('select', this.addressData[this.values], this.values)
        console.log(res.data.records, 'getAddress')
      })
    },
    onChange(checked) {
      this.isActive = checked
      console.log(`a-switch to ${checked}`)
    },
    changeIsDefault(val) {
      // 修改订单地址的逻辑
      console.log(val, '切换地址')
      this.$emit('select', val)
      let _this = this
      updateUserAddress({ id: val.id, isDefault: !val.isDefault ? 1 : 0 }).then((res) => {
        _this.isActive = true
        _this.getAddress()
      })
    },
    handleAdd() {
      this.mdl = null
      console.log('发送的公司的感受到公司的感受到')
      this.AddressModal = true
    },
    update(val) {
      console.log(val, 'sds')
      // this.values = ''
      this.mdl = val
      this.AddressModal = true
    },
    handleDelete(val) {
      deleteUserAddress(val.id).then((res) => {
        this.getAddress()
        this.$emit('del')
      })
    },
    showDeleteConfirm(item) {
      let _this = this
      this.$confirm('您确定删除此条地址吗?', '此操作不可逆', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        if (item.isDefault == 1) {
          _this.$message.info('默认地址不允许删除')
          return
        }
        _this.handleDelete(item)
      }).catch(() => {

      })
    },
    icout(val) {
      let _this = this
      console.log('ifsfsdf', val)
      this.$emit('selectIcount', val)
    },
  }
}
</script>

<style lang="scss" scoped>
.add {
  cursor: pointer;
}

::v-deep .ant-btn-group>.ant-btn:only-child {
  border-radius: unset;
}

.infoBox {
  .Avatar {
    height: 68px;
    width: 68px;
    border-radius: 50%;
    overflow: hidden;
    font-size: 42px;
    line-height: 80px;
    color: white;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff4723;

    span {
      margin-left: -10px;
      margin-bottom: 8px;
    }
  }

  .infoData {
    font-size: 16px;
    margin-left: 20px;
    font-weight: 600;
  }
}

.address_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  // border: 1px solid red;
  padding: 0 15px;

  .left {
    font-size: 14px;
    font-weight: 600;
  }

  .right {
    color: #4da4da;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
  }
}

.address_list {
  width: 100%;
  margin-top: 10px;
  // border: 1px solid red;
  padding: 10px 20px;
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: space-between;
  grid-template-rows: repeat(auto, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 30px;

  .address_mr {
    // width: 320px;
    // height: 200px;
    text-align: center;
    border: 1px dashed #afabab;
    background-color: #f7f7f7ee;
    position: relative;
    cursor: pointer;
    user-select: none;

    .mr_box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
      }

      >div {
        padding-top: 10px;
        color: #96ccf4;
      }
    }

  }

  .address_more {
    min-width: 650px;
    // border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    // height: 200px;
    margin-bottom: 15px;
    // box-shadow: 0 0 20px #a9b0b4;
    border-radius: 2px;
    // // position: relative;
    // user-select: none;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    img {
      position: absolute;
      left: 35px;
      top: 25px;
      width: 60px;
      height: 60px;
      object-fit: cover;
    }

    .btn {
      display: flex;
      right: 25px;
      top: 25px;
      margin-top: 15px;

      >div {
        cursor: pointer;
        color: #cc4179;
      }
    }

    .mr {
      right: 20px;
      bottom: 10px;
      color: #00be92;
      font-size: 12px;
      font-weight: 400;
    }

    .name_box {
      // position: absolute;
      // bottom: 60px;
      // left: 35px;
      // width: 220px;
      display: flex;
      justify-content: space-between;

      // border: 1px solid red;
      >div:nth-child(1) {
        font-weight: 600;
        font-size: .9rem;

        span {
          margin-right: 15px;
        }
      }

      >div:nth-child(2) {
        font-weight: 400;
        font-size: .5rem;
        color: #979292;
      }
    }

    .text {
      // border: 1px solid red;
      position: absolute;
      bottom: 15px;
      left: 35px;
      width: 220px;
      font-size: .7rem;
      font-weight: 400;
    }
  }
}

.active {
  background-color: #00be92;
}

.noActive {
  background-color: #eee;
}</style>
