<template>
  <a-modal
    :confirm-loading="confirmLoading"
    :visible="visible"
    centered
    title="填写物流单号"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <a-form ref="form" :form="form" layout="vertical">
      <a-form-item label="退款物流单号">
        <a-input
          v-decorator="['refundLogisticsNo',
                        { rules: [{required: true, message: '请输入退款物流单号'}] }]"
          placeholder="请输入退款物流单号"
        />
      </a-form-item>
      <a-form-item label="退款物流公司名称">
        <a-input
          v-decorator="['refundLogisticsName',
                        { rules: [{required: true, message: '请输入物流公司名称'},] }]"
          placeholder="请输入物流公司名称"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'
import { prefectRefundLogitics } from '@/api/Order'

export default {
  name: 'ExpressWaybillModal',
  mixins: [ ShowModalMixin ],
  props: {
    orderId: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      required: true
    },
  },
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this, { name: 'RefundForm' })
    }
  },
  methods: {
    handleOk() {
      this.confirmLoading = true
      this.form.validateFields((err, values) => {
        if (!err) {
          prefectRefundLogitics(Object.assign({ orderId: this.orderId }, values)).then((res) => {
            this.$notification.success({
              message: `填写退款物流成功`,
              description:
                `您已经成功填写退款物流成功`,
            })
            this.$emit('ok', res.data)
            this.closeModal()
            this.confirmLoading = false
          })
        }
      })
      this.confirmLoading = false
    }
  },
}
</script>

<style scoped>

</style>
