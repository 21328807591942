/**
 * @author  XuHongli
 * @date  2022/8/31 17:14
 * @version 1.0
 * @description
 */

export const TabsMenu = [
  {
    label: '所有订单',
    value: '-1'
  },
  {
    label: '等待付款',
    value: '0'
  },
  {
    label: '等待发货',
    value: '1'
  },
  {
    label: '等待收货',
    value: '2'
  },
  // {
  //   label: '异常订单',
  //   value: '22'
  // },
  {
    label: '已完成',
    value: '3'
  }
]
export const userOrderStatus = {
  '-1': {
    label: '已退款',
  },
  0: {
    label: '待发货',
  },
  1: {
    label: '待收货',
  },
  2: {
    label: '已收货',
  },
  3: {
    label: '已完成',
  },
  5: {
    label: '待付款',
  }
}
