<template>
  <a-modal
    title="取消订单"
    :centered="true"
    :visible="visible"
    @ok="handleOk"
    @cancel="closeModal"
  >
    <a-form-model ref="form" :model="form" :rules="rules" v-bind="layout">
      <a-form-model-item label="取消原因" prop="cancelRemake">
        <a-select v-model="form.cancelRemake" placeholder="请选择取消的理由">
          <a-select-option :value="item" v-for="(item, index) in OrderCancelRefunds" :key="index">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'
import { OrderCancelRefunds } from '@/views/User/Order/OrderCancel/OrderCancelRefunds'
import { cancleOrder } from '@/api/Order'
/**
 * @author  XuHongli
 * @date  2022/9/9 16:59
 * @version 1.0
 * @description
 */
export default {
  name: 'OrderCancel',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  mixins: [ ShowModalMixin ],
  data() {
    return {
      OrderCancelRefunds,
      form: {
        status: '5'
      },
      rules: {
        cancelRemake: [
          { required: true, message: '请选择退货理由', trigger: 'blur' },
        ],
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
    }
  },
  methods: {
    handleOk() {
      this.$refs.form.validate((err) => {
        if (err) {
          window.history.replaceState(null, null, window.location.href.replace(new RegExp('true', 'g'), ''))
          cancleOrder(Object.assign({ orderId: this.id }, this.form)).then((res) => {
            this.$emit('ok', res.data)
            this.closeModal()
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
