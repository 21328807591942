<template>
  <div class="OrderTable bg-white p-10">
    <div class="orderTable" style="padding: 20px 0">
      <!-- 条件  -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="10">
            <a-col :md="8" :sm="24">
              <a-form-item label="订单号/产品名称">
                <a-input v-model="queryParam.id" placeholder="请输入订单号/产品名称" size="small" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-range-picker ref="rangePicker" v-model="selectTime"
                :ranges="{ '今天': [moment(), moment()], '本月': [moment().startOf('month'), moment().endOf('month')] }"
                size="small" @change="rangePickerOnChange" />
            </a-col>
            <!--            <template v-if="advanced">-->
            <!--            </template>-->
            <a-col :md="!advanced && 8 || 8" :sm="24">
              <span :style="advanced && { float: 'right', overflow: 'hidden' } || {}"
                class="table-page-search-submitButtons">
                <a-button size="small" type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button size="small" style="margin-left: 8px"
                  @click="() => { this.selectTime = []; this.queryParam = { status: 22 } }">重置</a-button>
                <!--                注：异常订单不需要显示-->
                <!--                <a-dropdown>-->
                <!--                  <a-menu slot="overlay" @click="DropdownOnClick">-->
                <!--                    <a-menu-item key="1">-->
                <!--                      <a-icon type="build" />-->
                <!--                      批量付款-->
                <!--                    </a-menu-item>-->
                <!--                    &lt;!&ndash; lock | unlock &ndash;&gt;-->
                <!--                    &lt;!&ndash;            <a-menu-item key="2">&ndash;&gt;-->
                <!--                    &lt;!&ndash;              <a-icon type="lock"/>&ndash;&gt;-->
                <!--                    &lt;!&ndash;              锁定&ndash;&gt;-->
                <!--                    &lt;!&ndash;            </a-menu-item>&ndash;&gt;-->
                <!--                  </a-menu>-->
                <!--                  <a-button size="small" style="margin-left: 8px">-->
                <!--                    批量操作-->
                <!--                    <a-icon type="down"/>-->
                <!--                  </a-button>-->
                <!--                </a-dropdown>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <s-table ref="table" :alert="true" :columns="columns" :data="loadData" :defaultExpandAllRows="true" :pagination="{
        pageSizeOptions: ['10', '20', '30', '40', '100', '500']
      }" :rowSelection="Number(queryParam.status) === 0 ? rowSelection : undefined" :scroll="{ x: 900, y: 600 }"
        rowKey="id" showPagination="auto" size="middle">
        <div slot="description" slot-scope="text, record">
          <div class="td-box">
            <div class="td-mt">
              <span class="text-gary verdana">{{ record.createTime }}</span>
              <span class="text-gary ml-30">订单号：</span>
              <span class="verdana">{{ record.id }}</span>
            </div>
            <div class="td-mc" style="border-left: 1px solid #e5e5e5">
              <span v-for="(supplyOrderItem, index) in record.supplyOrderItem" :key="supplyOrderItem.id + '-' + index"
                class="flex-row flex-col-center">
                <div class="flex-row flex-col-center" style="width: 320px;cursor: pointer"
                  @click="$router.push({ path: '/commodity/' + record.productId })">
                  <img :src="supplyOrderItem.picUrl" height="80" style="padding: 5px" width="80">
                  <div class=" flex-column flex-row-between" style="padding: 5px">
                    <Ellipsis :length="48" tooltip>{{ supplyOrderItem.name }}</Ellipsis> <br>
                    <Ellipsis :length="24" style="margin-top: auto" tooltip> {{ supplyOrderItem.specInfo }} </Ellipsis>
                  </div>
                </div>
                <span class="text-gary" style="margin-left: 75px;"> X{{ supplyOrderItem.quantity }}</span>
              </span>
            </div>
          </div>
        </div>

        <div slot="salesPrice" slot-scope="text, record">
          <div class="td-box">
            <div class="td-mt"></div>
            <div class="td-mc">
              <div v-for="(supplyOrderItem, index) in record.supplyOrderItem" :key="supplyOrderItem.id + '-' + index">
                总价：<span style="color: #ff0000;font-weight: bold;">￥{{ Number((supplyOrderItem.salesPrice * supplyOrderItem.quantity) + record.freightPrice).toFixed(2) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div slot="orderLogistics" slot-scope="text, record">
          <div class="td-box">
            <div class="td-mt"></div>
            <div class="td-mc">
              <p>{{ record.orderLogistics.userName }}</p>
              <p>{{ record.orderLogistics.telNum }}</p>
            </div>
          </div>
        </div>
        <!--        订单流程状态展示-->
        <span slot="status" slot-scope="text, record">
          <span class="td-box">
            <span class="td-mt"></span>
            <span class="td-mc">
              <template v-if="record.refundStatus == null || record.refundStatus == '20'">
                <span class="text-gary">{{ text | statusFilter }}</span>
              </template>
              <template v-else>
                <span class="text-gary">{{ record.refundStatus | afterSaleFilter }}</span>
              </template>
              <a-button size="small" style="color:  #409eff" type="link"
                @click="$router.push('/user/orderTableDetail/' + record.id)">订单详情 </a-button>
            </span>
          </span>
        </span>
        <span slot="action" slot-scope="text, record">
          <div class="td-box">
            <div class="td-mt"></div>
            <div class="td-mc" style="border-right: 1px solid #e5e5e5">
              <template v-if="Number(record.status) === 0">
                <a-button size="small" type="link"
                  @click="$router.push({ path: '/user/order/detail/' + record.id, query: { open: true } })">立刻付款</a-button>
                <a-button size="small" type="link" @click="cancelOrder(record)">取消订单</a-button>
                <a-button size="small" type="link" @click="updateOrder(record)">修改订单</a-button>
              </template>
              <template
                v-if="Number(record.status) === 1 || Number(record.status) === 2 && (record.refundStatus === null || record.refundStatus == 20)">
                <a-button v-if="Number(record.status) === 2" size="small" type="link"
                  @click="notarizeOrder(record)">确认收货</a-button>
              </template>
              <a-button
                v-if="(record.status == '1' || record.status == '2' || record.status == '3') && record.refundStatus == null || record.refundStatus == 20"
                size="small" type="link" @click="refundOrder(record)">申请售后</a-button>
              <a-button v-if="record.refundStatus == 21 || record.refundStatus == 22" size="small" type="link"
                @click="revocationOrder(record)">撤销售后</a-button>
              <template v-if="Number(record.status) === 6">
                <a-button size="small" type="link" @click="updateOrder(record)">修改订单</a-button>>
              </template>
            </div>
          </div>
        </span>
      </s-table>
    </div>
    <ExpressWaybillModal v-model="ModalExpressWaybill" :order-id="selectOrderId"
      @ok="() => { $refs.table.refresh(true) }"></ExpressWaybillModal>
    <!--  选择支付方式弹窗  -->
    <PaymentMethodModal v-model="ModalPayment" @ok="payOrders"></PaymentMethodModal>
    <!--  退款弹窗  -->
    <RefundModal :id="selectOrderId" v-model="ModalRefund" :selectOrderObj="selectOrderObj" @ok="RefundOrderFinish">
    </RefundModal>
    <!--  修改订单地址  -->
    <AddressSelectModal v-model="ModalUpdateOrder" @select="updateOrderAddress"></AddressSelectModal>
    <!--  取消订单  -->
    <OrderCancel :id="selectOrderId" v-model="ModalOrderCancel" @ok="CancelOrderFinish"></OrderCancel>
    <!--  微信支付二维码弹窗  -->
    <WechatPayModal v-model="ModalWechatPayModal" :codeUrl="codeUrl" :orderId="PayOrdersId" :orderSn="''">
    </WechatPayModal>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/1 14:55
 * @version 1.0
 * @description 异常订单表格
 */

import moment from 'moment'
import { TabsMenu, userOrderStatus } from '@/views/User/Order/OrderTable/TabsMenu'
import STable from '@/components/Table'
import Ellipsis from '@/components/Ellipsis'
import { ConfirmGoods, getOrdersNum, getUserOrderPageList, payOrders, revocationOrder, updateOrder } from '@/api/Order'
import { OrderRefundStatusMap, OrderStatusMap } from '@/Map/OrderStatusMap'
import { columns } from '@/views/User/Order/OrderTable/OrderTableColumns'
import OrderCancel from '@/views/User/Order/OrderCancel'
import AddressSelectModal from '@/components/AddressSelect/Modal/AddressSelectModal'
import RefundModal from '@/components/Modal/RefundModal'
import PaymentMethodModal from '@/components/Modal/PaymentMethodModal'
import WechatPayModal from '@/components/Modal/WechatPayModal'
import { mapGetters } from 'vuex'
import ExpressWaybillModal from '@/components/Modal/ExpressWaybillModal'


const aftermarketMap = OrderRefundStatusMap
aftermarketMap[231].text = '已验收'
const statusMap = OrderStatusMap
export default {
  name: 'ExceptionOrderTable',
  components: {
    ExpressWaybillModal,
    WechatPayModal,
    PaymentMethodModal,
    RefundModal,
    AddressSelectModal,
    OrderCancel,
    STable,
    Ellipsis,
  },
  watch: {
    queryParam: {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue.status) {
          this.$refs.table.refresh(true)
        }
      }
    }
  },
  data() {
    return {
      dataInfo: [],
      selectOrderObj: {},
      selectTime: [],
      moment,
      // table字段
      columns: columns,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        status: 22
      },
      // 选择订单Id
      selectOrderId: '',
      selectOrderNo: '',
      ModalExpressWaybill: false,
      ModalRefund: false,
      ModalUpdateOrder: false, // 修改订单弹窗
      ModalOrderCancel: false, // 取消订单弹窗
      ModalPayment: false, // 批量付款选择支付方式弹窗
      ModalWechatPayModal: false, // 微信支付二维码弹窗
      PayOrdersId: '', // 批量下单后重新生成的ID
      codeUrl: '', // 微信支付二维码地址
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const that = this
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
          return getUserOrderPageList(requestParameters)
            .then(res => {
              console.log('res订单列表:', res)
              this.selectedRowKeys = []
              this.selectedRows = []
              // that._getOrdersNum()
              return res.data
            })
        }
      },
      selectedRowKeys: [],
      selectedRows: [],
      orderInfo: {},

    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
  },
  filters: {
    afterSaleFilter(type) {
      // console.log('交易状态文本', type)
      return aftermarketMap[type].text
    },
    afterSaleTypeFilter(type) {
      // console.log('交易状态文本', type)
      return aftermarketMap[type].refundStatus
    },
    statusFilter(type) {
      // console.log('交易状态文本', type)
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      // console.log('交易状态', type)
      return statusMap[type].status
    },
    userStatusFilter(type) {
      // console.log('用户订单状态', type)
      return userOrderStatus[type].label
    },
  },
  created() {
    // F5 刷新
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.$refs.table.refresh(true)
    //   }, 100)
    // })
    /* 在异常订单中没有异常商城用户状态，所有不要显示 */
    const idToRemove = 'userStatus'
    this.columns = columns.filter(obj => obj.dataIndex !== idToRemove)
  },
  methods: {
    // 获取订单数量
    _getOrdersNum() {
      const that = this
      getOrdersNum().then(res => {
        that.orderInfo = {
          productNum: res.data.productNum,
          orderCount: res.data.orderCount,
          orderPrice: res.data.orderPrice
        }
      })
    },
    DropdownOnClick({ key }) {
      console.log(key)
      if (key === '1') { this.ModalPayment = true }
    },
    inputExpressWaybill(record) {
      this.selectOrderId = record.id
      this.ModalExpressWaybill = true
    },
    /**
     * 批量下单
     * @param payType { string }
     */
    payOrders(payType) {
      console.log('批量下单', payType, this.selectedRows, this.selectedRowKeys,)
      payOrders({ orderIds: this.selectedRowKeys, payType }).then((res) => {
        if (payType === 'ali') {
          window.location.href = res.data.payUrl
        } else if (payType === 'wx') {
          console.log(res.data)
          this.codeUrl = res.data.payUrl.codeUrl
          this.PayOrdersId = res.data.orderId
          this.ModalWechatPayModal = true
        }
      })
    },
    /**
     * 日期选择器回调
     * @param dates
     * @param dateStrings
     */
    rangePickerOnChange(dates, dateStrings) {
      this.queryParam.startTime = dateStrings[0]
      this.queryParam.endTime = dateStrings[1]
    },
    /**
     * 修改订单地址
     * @param val
     */
    updateOrderAddress(val) {
      console.log(val)
      updateOrder({ orderId: this.selectOrderId, addressId: val.id }).then((res) => {
        this.$notification.success({
          message: '修改地址订单成功',
          description: '您已成功修改订单地址'
        })
        this.$refs.table.refresh(true)
      })
    },
    /**
     * 取消订单完成后 取消订单逻辑在组件内部
     * @constructor
     */
    CancelOrderFinish() {
      this.$notification.success({
        message: '取消订单成功',
        description: '您已成功取消该订单'
      })
      this.$refs.table.refresh(true)
    },
    RefundOrderFinish(data) {
      this.$notification.success({
        message: data,
        description: '您已成功申请售后，请耐心等待工作人员审核'
      })
      this.$refs.table.refresh(true)
    },
    /**
     * 点击取消订单
     * @param record
     */
    cancelOrder(record) {
      this.selectOrderId = record.id
      this.ModalOrderCancel = true
      this.selectOrderNo = record.orderNo
    },
    /**
     * 点击申请售后
     * @param record
     */
    refundOrder(record) {
      this.selectOrderId = record.id
      this.selectOrderNo = record.orderNo
      this.selectOrderObj = record
      this.ModalRefund = true
    },
    /**
     * 点击撤销申请
     * @param record
     */
    revocationOrder(record) {
      const that = this
      this.$confirm('确认要撤销这单售后申请？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        revocationOrder(record.id).then(res => {
          that.$notification.success({
            message: '售后申请撤销成功',
            description: '您已成功取消售后申请'
          })
          that.$refs.table.refresh(true)
        })
      })

    },
    /**
     * 点击确认收货
     * @param record
     */
    notarizeOrder(record) {
      this.selectOrderId = record.id
      const that = this
      this.$confirm('确认要收货？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        ConfirmGoods(record.id).then(res => {
          that.$notification.success({
            message: '确认收货成功',
            description: '您已成功取消确认收货'
          })
          that.$refs.table.refresh(true)
        }).catch(err => {
          console.error(err)
        })
      })
    },
    /**
     * 点击修改订单
     * @param record
     */
    updateOrder(record) {
      this.selectOrderId = record.id
      this.selectOrderNo = record.orderNo
      this.ModalUpdateOrder = true
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log('onSelectChange', selectedRowKeys, selectedRows)
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .ant-table {
  font-size: 12px;
  color: #000;
}

.text-gary {
  color: #aaa
}
</style>
